@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Raleway&display=swap);
.header-nav {
  display: flex;
  align-items: center;
  height: var(--header-height);
  padding: 0px 1rem;
  position: fixed;
  width: 100vw;
  background-color: var(--dark-blue);
  -webkit-filter: blur(0);
          filter: blur(0);
  z-index: 100;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 10px;
  font-family: "Raleway", sans-serif;
}
.burger-nav {
  display: none;
  z-index: 1;
}
.burger-nav div {
  width: 25px;
  height: 3px;
  margin: 5px;
  background-color: white;
}
.logo-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex: 1 1;
}
.logo-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  color: white;
}
.logo {
  width: 30px;
}
.logo-name {
  font-size: 1.5rem;
}
.main-nav {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex: 1 1;
}
.main-nav * {
  list-style: none;
  color: white;
  text-decoration: none;
}
.main-nav *:hover {
  color: lightgrey;
}
.blur {
  -webkit-filter: blur(3px);
          filter: blur(3px);
}
.no-scroll {
  overflow: hidden;
}

@media screen and (max-width: 800px) {
  .header-nav {
    justify-content: center;
  }
  .logo-container {
    justify-content: center;
  }
  .logo-name {
    font-size: 1.5rem;
  }
  .main-nav {
    position: absolute;
    left: -2rem;
    height: 100vh;
    width: 250px;
    top: 0;
    background-color: #263137;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    text-align: center;
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
    transition: -webkit-transform 0.25s ease-in;
    transition: transform 0.25s ease-in;
    transition: transform 0.25s ease-in, -webkit-transform 0.25s ease-in;
    box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.25);
  }
  .burger-nav {
    display: inline;
    position: absolute;
    left: 5%;
  }
  .nav-active {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
    transition: -webkit-transform 0.25s ease-in;
    transition: transform 0.25s ease-in;
    transition: transform 0.25s ease-in, -webkit-transform 0.25s ease-in;
  }
  .toggle:nth-child(1) {
    -webkit-transform: rotate(-45deg) translate(-5px, 6px);
            transform: rotate(-45deg) translate(-5px, 6px);
    transition: -webkit-transform 0.25s;
    transition: transform 0.25s;
    transition: transform 0.25s, -webkit-transform 0.25s;
  }
  .toggle:nth-child(2) {
    opacity: 0;
  }
  .toggle:nth-child(3) {
    -webkit-transform: rotate(45deg) translate(-5px, -6px);
            transform: rotate(45deg) translate(-5px, -6px);
    transition: -webkit-transform 0.25s;
    transition: transform 0.25s;
    transition: transform 0.25s, -webkit-transform 0.25s;
  }
}

.landing-page-container {
	display: flex;
	flex-direction: column;
	z-index: -1;
}
.first-page {
	height: 100vh;
	display: flex;
	flex-direction: column;
}
.mid-container {
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	align-items: center;
	flex: 1 1;
	margin: 6rem 0 2rem 0;
	padding: 0 2rem 0 2rem;
}
.keyboard {
	width: 45vw;
	z-index: -10;
}
.main-content {
	display: flex;
	justify-content: space-around;
	flex-direction: column;
	align-items: flex-start;
	color: white;
}
h1 {
	font-size: 2.5rem;
	color: white;
	font-weight: 400;
}
h3 {
	font-weight: 400;
	padding: 2rem 0 2rem 0;
	/* color: #bbc9d0; */
	color: white;
	font-size: 1.75rem;
	width: 75%;
	text-align: left;
}
.cta-button {
	background-color: #ffc58f;
	color: #263137;
	border: none;
	border-radius: 20px;
	font-size: 1rem;
	padding: 0.75rem;
	width: 200px;
	cursor: pointer;
}
.second-page {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100vh;
}
.second-page .waves {
	-webkit-transform: rotate(180deg);
	        transform: rotate(180deg);
}
.cards-container {
	display: flex;
	justify-content: space-evenly;
	padding: 2.5rem 0rem 2.5rem 0rem;
}
.card {
	background-color: #bbc9d0;
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: center;
	width: 350px;
	height: auto;
	text-align: center;
}
.card-illustration {
	width: 300px;
	padding: 1rem;
}
.card-title {
	font-size: 1.5rem;
}
.card-text {
	padding: 1rem;
}
.cta-last {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 2.5rem 0 2.5rem 0;
}
.cta-last p {
	color: white;
	font-size: 1.75rem;
	padding-bottom: 1.5rem;
}

@media screen and (max-width: 1065px) {
	.main-content {
		margin-right: 3rem;
	}
	h1 {
		font-size: 2.5rem;
	}
	h3 {
		font-size: 1.5rem;
		width: 100%;
	}
	.second-page {
		height: auto;
	}
	.cards-container {
		flex-direction: column;
		align-items: center;
	}
	.card {
		width: 50vw;
		margin: 2.5rem 0 2.5rem 0;
	}
	.card-text {
		width: 90%;
	}
	.card-illustration {
		width: 30vw;
	}
	.cta-last {
		padding-top: 0;
	}
}

@media screen and (max-width: 800px) {
	.mid-container {
		flex-direction: column;
		justify-content: space-between;
		/* margin-top: 4rem; */
		padding: 0 1rem;
		overflow: hidden;
		margin-bottom: 0;
	}
	.keyboard {
		-webkit-transform: scale(2.75);
		        transform: scale(2.75);
		width: 300px;
		padding-bottom: 5rem;
		-webkit-filter: blur(1px);
		        filter: blur(1px);
	}

	.main-content {
		align-items: center;
		justify-content: center;
		margin: 0;
		padding-top: 4rem;
		text-align: center;
	}
	h1 {
		font-size: 2.25rem;
	}
	h3 {
		font-size: 1.25rem;
		width: 80%;
		text-align: center;
	}
	.card {
		width: 65vw;
	}
	.card-illustration {
		width: 40vw;
	}
	.card-text {
		font-size: 0.85rem;
		width: 90%;
	}
}

@media screen and (max-width: 470px) {
	h1 {
		font-size: 2rem;
	}
	h3 {
		width: 95%;
	}
	.card {
		width: 80vw;
	}
	.card-illustration {
		width: 60vw;
	}
	.cta-last {
		margin-bottom: 1rem;
	}
	.cta-last p {
		text-align: center;
		font-size: 1.5rem;
	}
}

.loading-animation {
  position: relative;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loading-animation span {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 2px solid;
  pointer-events: none;
  -webkit-animation: load 3s linear infinite;
          animation: load 3s linear infinite;
}
.loading-animation span:nth-child(1) {
  border-radius: 57% 43% 56% 44% / 48% 62% 38% 52%;
  border-color: white;
}
.loading-animation span:nth-child(2) {
  animation-direction: reverse;
  border-radius: 74% 26% 69% 31% / 38% 75% 25% 62%;
  border-color: var(--blue);
}
.loading-animation span:nth-child(3) {
  -webkit-animation-duration: 3s;
          animation-duration: 3s;
  border-radius: 35% 65% 69% 31% / 38% 50% 50% 62%;
  border-color: var(--darker-blue);
}
@-webkit-keyframes load {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes load {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.post-small {
	--border-radius: 10px;
	--post-width: 600px;
	height: auto;

	display: grid;
	grid-template-columns: var(--post-width);
	grid-template-rows: 1fr auto;

	/* display: flex; */
	/* flex-direction: column; */
	/* align-items: center; */

	background-color: var(--darkest-blue);
	/* width: var(--post-width); */

	-webkit-transform: scale(0.8);

	        transform: scale(0.8);
	-webkit-transform-origin: center;
	        transform-origin: center;
	transition: all 0.5s;
	box-shadow: 0px 0px 10px rgba(187, 201, 208, 0.2);

	border-radius: var(--border-radius);
	overflow: hidden;
}
.post-small-actions {
	border-top-right-radius: var(--border-radius);
	border-bottom-right-radius: var(--border-radius);
}
.post-small-container {
	width: auto;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	/* box-shadow: 0px 0px 15px rgba(187, 201, 208, 0.2); */
	color: white;
	min-height: 150px;
	height: auto;
	/* width: 600px; */

	/* margin: 1rem; */
	/* z-index: 1; */
	/* border-radius: var(--border-radius); */
}
.post-image-container {
	border-top-left-radius: var(--border-radius);
	border-bottom-left-radius: var(--border-radius);

	/* height: 100%; */
	/* min-height: 150px; */
	/* height: 150px; */
	/* width: calc(var(--post-width) * 0.45); */
	width: 45%;
	height: 100%;
	overflow: hidden;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}
.post-small-hero-image-none {
	width: 100%;
	height: 100%;
	object-fit: contain;
	cursor: default;
}
.post-small-hero-image-na {
	width: 100%;
	height: 100%;
	object-fit: cover;
}
.post-small-hero-image-foreground {
	position: absolute;
	width: 100%;
	height: 100%;
	object-fit: contain;
}
.post-small-hero-image-background {
	position: absolute;
	width: 100%;
	height: 100%;
	object-fit: cover;
	-webkit-filter: blur(3px);
	        filter: blur(3px);
	opacity: 0.3;
}
.post-small-wrapper {
	/* width: calc(100% - 225px); */
	width: 100%;
	height: 100%;
	/* height: calc(100% - 2rem); */
	/* padding: 1rem; */
	display: flex;
	justify-content: space-between;
	/* gap: 1rem; */
}
.post-small-info {
	width: 100%;
	/* height: calc(100% - 2rem); */
	height: 100%;
	padding: 1rem;
	/* padding: 1.5rem 1rem; */
	display: flex;
	flex-direction: column;
	/* justify-content: space-around; */
	justify-content: space-between;
	align-items: left;
	grid-row-gap: 1rem;
	row-gap: 1rem;
	overflow: hidden;
	flex: 1 1;
	/* height: calc(100% - 2rem); */
	/* width: calc(100% - 30% - 2rem); */
	/* padding: 1rem; */
}
.post-small-header {
	display: flex;
	grid-gap: 5px;
	gap: 5px;
}

.post-small-title {
	word-break: break-word;
	overflow-x: initial;
	/* border-top: 1px solid white; */
	/* box-shadow: inset 0 1px 0 #fff; */
	text-underline-position: under !important;

	font-size: 1.2rem;
	color: inherit;
	line-height: 150%;
}

.post-small-sub {
	display: flex;
	align-items: center;
	grid-gap: 0.25rem;
	gap: 0.25rem;
}

.post-small-type {
	border-radius: 12px;
	height: 16px;
	font-size: 0.75em;
	display: grid;
	place-content: center;
	text-align: center;
	padding: 0 8px;
	width: auto;
}
.post-small-type.ic {
	background-color: #b0e0e6;
	color: #191970;
}
.post-small-type.gb {
	background-color: #f5f5dc;
	color: #b22222;
}

.post-small-creator {
	color: var(--dark-grey);
	letter-spacing: 0.1rem;
	font-size: 0.85rem;
	font-style: italic;
}

.post-small-date {
	display: flex;
	justify-content: space-between;
	align-items: baseline;

	background-image: linear-gradient(
		to right,
		rgba(255, 255, 255, 0.25) 10%,
		rgba(0, 0, 0, 0) 0%
	);
	background-position: 0 16px;
	/* 12 */
	background-size: 10px 1px;
	background-repeat: repeat-x;
}
.post-small-date span {
	background-color: var(--darkest-blue);
}
.post-small-date span:first-child {
	font-weight: 200;
	font-family: "Poppins", sans-serif;
	padding-right: 10px;
}
.post-small-date span:last-child {
	font-family: "Raleway", sans-serif;
	padding-left: 10px;
}

.post-small-actions {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	background-color: var(--dark-blue);
}
.post-small-button {
	cursor: pointer;
	background-color: transparent;
	border: none;
	box-shadow: none;
	padding: 1rem 0.5rem;
	height: auto;
}
.post-small-button:hover,
.post-small-button:focus {
	background-color: rgba(255, 255, 255, 0.025);
}
.post-small-button:hover .post-small-icon {
	color: var(--orange);
}
.post-small-icon {
	width: 20px;
	aspect-ratio: 1;
	/* height: 100%; */
	color: rgba(255, 255, 255, 0.15);
}

.skeleton {
	background: linear-gradient(-90deg, #dfdfdf 0%, #f8f8f8 50%, #dfdfdf 100%);
	background-size: 400% 400%;
	-webkit-animation: loading 1s infinite;
	        animation: loading 1s infinite;
	border-radius: 5px;
}
.post-small-wrapper.skeleton,
.post-small-actions.skeleton {
	background: white;
	-webkit-animation: none;
	        animation: none;
	border-radius: initial;
}
.post-small-wrapper.skeleton {
	box-shadow: none;
}
.post-image-container.skeleton {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}
.post-small-title.skeleton {
	width: 100%;
	height: 1.2rem;
}
.post-small-type.skeleton {
	width: 30px;
	border-radius: 12px;
}
.post-small-creator.skeleton {
	/* height: 0.85rem; */
	height: 16px;
	width: 20%;
}
.post-small-date span.skeleton {
	height: 16px;
}
.post-small-date span.skeleton:first-child {
	padding-left: 75px;
}
.post-small-date span.skeleton:last-child {
	padding-left: 50px;
}
.post-small-icon.skeleton {
	color: transparent;
}
.post-small-button.skeleton:hover * {
	color: transparent;
}
.post-small-button.skeleton {
	background: unset !important;
	cursor: unset;
}

.slide-in {
	-webkit-transform: scale(1);
	        transform: scale(1);
	-webkit-transform-origin: center;
	        transform-origin: center;
	transition: all 0.25s;
}

@-webkit-keyframes loading {
	0% {
		background-position: 0% 0%;
	}
	100% {
		background-position: -140% 0%;
	}
}

@keyframes loading {
	0% {
		background-position: 0% 0%;
	}
	100% {
		background-position: -140% 0%;
	}
}

@media screen and (max-width: 650px) {
	.post-small {
		--post-width: calc(100vw - 1.5rem);
	}
	.post-small-container {
		justify-content: unset;
		flex-direction: column;
	}
	.post-image-container {
		width: var(--post-width);
		height: calc(var(--post-width) / 2);
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
	}
	.post-small-wrapper {
		box-shadow: inset 0px 0px 15px rgba(0, 0, 0, 0.15);
		height: auto;
		flex: 1 1;
		grid-row-gap: 0.5rem;
		row-gap: 0.5rem;
	}
	.post-small-info {
		padding: 0.5rem;
		padding-right: 0;
		width: calc(100% - 1rem);
		height: auto;
	}
	.post-small-title {
		font-size: 1rem;
	}
	.post-small-sub {
		grid-gap: 0.5rem;
		gap: 0.5rem;
	}
	.post-small-date {
		background-position: 0 12px;
		background-size: 10px 2px;
	}
	.post-small-date span {
		line-height: 100%;
	}
	.post-small-date span:first-child {
		font-size: 85%;
	}
	.post-small-date span:last-child {
		font-size: 85%;
		text-align: right;
		flex: 1 1;
	}
	.post-small-actions {
		background: transparent;
		border-top-right-radius: 0;
	}
	.post-small-button {
		padding: 0.5rem 0.75rem;
	}
	.post-small-icon {
		width: 1.125rem;
	}
	.post-small-date span.skeleton {
		flex-grow: 0;
	}
	.post-small-date span.skeleton:first-child {
		/* width: unset; */
		width: 100px;
	}
	.post-small-date span.skeleton:last-child {
		/* width: unset; */
		width: 150px;
	}
}

/* .post-small-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 0px 15px rgba(187, 201, 208, 0.2);
  background-color: var(--darkest-blue);
  color: white;
  width: 600px;
  height: 150px;
  margin: 1rem;
  z-index: 1;
}
.post-small-image-container {
  width: 40%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.post-small-hero-image-none {
  width: 100%;
  height: 100%;
  object-fit: contain;
  cursor: default;
}
.post-small-hero-image-na {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.post-small-hero-image-foreground {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.post-small-hero-image-background {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: blur(3px);
  opacity: 0.3;
}
.post-small-info {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: left;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 100%;
  width: calc(100% - 30%);
  padding: 0.5rem;
}
.post-small-title {
  white-space: nowrap;
  overflow-x: hidden;
  font-size: 1.2rem;
  letter-spacing: 0.1.5rem;
  color: inherit;
}
.post-small-creator {
  color: var(--dark-grey);
  letter-spacing: 0.1rem;
  font-size: 0.85rem;
  font-style: italic;
}
.post-small-date-type {
  letter-spacing: 0.05rem;
  font-family: "Poppins", sans-serif;
}
.post-small-date {
  font-size: 0.85rem;
  margin-left: 10px;
  letter-spacing: 0.1rem;
}
.skeleton {
  background: linear-gradient(-90deg, #dfdfdf 0%, #f8f8f8 50%, #dfdfdf 100%);
  background-size: 400% 400%;
  animation: loading 1s infinite;
}
.skeleton-text {
  width: 100%;
  height: 10px;
  margin: 0.5rem 0;
}
.skeleton-text:nth-child(2) {
  width: 30%;
}
.skeleton-text:nth-child(5) {
  width: 75%;
}
@keyframes loading {
  0% {
    background-position: 0% 0%;
  }
  100% {
    background-position: -140% 0%;
  }
}

@media screen and (max-width: 650px) {
  .post-small-container {
    width: 90vw;
    height: 125px;
    margin: 0.75rem;
  }
  .post-small-title {
    font-size: 1rem;
  }
  .post-small-creator {
    font-size: 0.5rem;
  }
  .post-small-date-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
  }
  .post-small-date-type {
    font-size: 0.6rem;
  }
  .post-small-date {
    margin-left: 0;
    white-space: pre-wrap;
    font-size: 0.7rem;
    text-align: right;
  }
} */

.comments-container {
	width: auto;
	overflow: hidden;
	background-color: var(--darkest-blue);
	/* background-color: #21272d; */

	display: flex;
	flex-direction: column;
	--comment-padding: 5px;
	grid-gap: calc(var(--comment-padding) * 1.5);
	gap: calc(var(--comment-padding) * 1.5);
	padding: var(--comment-padding);
	font-family: "Poppins", sans-serif;
	font-weight: 200;
	/* max-height: 500px; */
	max-height: calc((150px * 3) + (calc(var(--comment-padding) * 1.5) * 2));
	overflow-y: auto;
}
.scrolltop {
	display: block;
	position: -webkit-sticky;
	position: sticky;
	/* top: calc(var(--header-height) + 150px + 16px); */
	/* top: 50%; */
	top: 0;
	align-self: left;
	width: 20px;
	aspect-ratio: 1;
	border-radius: 25px;
	border: none;
	background-color: white;
	z-index: 10;
}
.progressbar {
	width: 100%;
	position: relative;
	overflow: hidden;
	display: block;
	height: 1px;
	z-index: 0;
	background-color: var(--dark-blue);
	border-radius: 16px;
}
.pill {
	position: absolute;
	left: 0;
	bottom: 0;
	top: 0;
	border-radius: 16px;
	transition: -webkit-transform 0.2s linear;
	transition: transform 0.2s linear;
	transition: transform 0.2s linear, -webkit-transform 0.2s linear;
	-webkit-transform-origin: left;
	        transform-origin: left;
	background-color: var(--orange);
	width: auto;
}
.pill:first-child {
	-webkit-animation: animation-test 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395)
		infinite;
	        animation: animation-test 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395)
		infinite;
}
@-webkit-keyframes animation-test {
	0% {
		left: -35%;
		right: 100%;
	}
	60% {
		left: 100%;
		right: -90%;
	}
	100% {
		left: 100%;
		right: -90%;
	}
}
@keyframes animation-test {
	0% {
		left: -35%;
		right: 100%;
	}
	60% {
		left: 100%;
		right: -90%;
	}
	100% {
		left: 100%;
		right: -90%;
	}
}
.pill:last-child {
	-webkit-animation: animation-test1 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) 1.15s
		infinite;
	        animation: animation-test1 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) 1.15s
		infinite;
}
@-webkit-keyframes animation-test1 {
	0% {
		left: -200%;
		right: 100%;
	}
	60% {
		left: 107%;
		right: -8%;
	}
	100% {
		left: 107%;
		right: -8%;
	}
}
@keyframes animation-test1 {
	0% {
		left: -200%;
		right: 100%;
	}
	60% {
		left: 107%;
		right: -8%;
	}
	100% {
		left: 107%;
		right: -8%;
	}
}
.comments-container h2 {
	font-size: 115%;
	color: white;
}
.comment-container {
	background-color: rgba(255, 255, 255, 0.03);
	border-radius: 5px;
	/* padding: 10px; */
	display: flex;
	flex-direction: column;
	grid-gap: 0.5rem;
	gap: 0.5rem;

	font-size: 100%;

	/* --max-lines: 3;
	--line-height: 1.5; */

	/* overflow: hidden; */
	position: relative;
	border-bottom: 1px solid rgba(255, 255, 255, 0.03);
	/* padding-bottom: 10px; */
	padding-bottom: calc(1em * 0.5);
}
.comment-container > * {
	padding: 0 0.5rem;
}
.comment-container img[class="smiley"] {
	height: 1.5em;
	width: -webkit-max-content;
	width: max-content;
	/* overflow-clip-margin: content-box;
	overflow: clip;
	vertical-align: bottom; */
}
.comment-info {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 5px;
	background-color: rgba(255, 255, 255, 0.015);
	grid-gap: 10px;
	gap: 10px;
}
.comment-reply {
	display: flex;
	align-items: center;
	grid-gap: 5px;
	gap: 5px;
	text-decoration: none;
	flex-shrink: 0;
}
.comment-date {
	font-size: 0.75em;
	color: rgba(255, 255, 255, 0.25) !important;
	flex-shrink: 0;
}
.comment-commenter {
	flex-shrink: 1;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.starter {
	color: var(--orange) !important;
}
[data-tooltip] {
	position: relative;
	cursor: pointer;
}
.comment-info *:first-child,
.comment-info *:last-child {
	color: rgba(255, 255, 255, 0.5);
	font-size: 0.9em;
}
.comment-icon {
	width: 1rem;
	height: auto;
	/* height: 1rem; */
	/* width: auto; */
	opacity: 0.25;
}
.comment-container * {
	max-width: 100% !important;
	color: white;
}
.comment-container a {
	word-break: break-all;
}

.comment-message {
	max-height: calc(var(--max-lines) * 1em * var(--line-height));
	--max-height: calc(var(--max-lines) * 1em * var(--line-height));
	line-height: var(--line-height);
	overflow: hidden;
	display: flex;
	flex-direction: column;

	/* max-height: none; */
}

.quote {
	background-color: rgba(255, 255, 255, 0.03);
	/* border-left: 2px solid var(--orange); */
	border-left: 2px solid white;
	padding: 10px;
	display: flex;
	flex-direction: column;
	grid-gap: 0.5rem;
	gap: 0.5rem;
	position: relative;
	margin-bottom: 0.25rem;
}
.quote-top {
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-size: 0.8em;
}
.quote-from {
	display: flex;
	grid-gap: 0.25rem;
	gap: 0.25rem;
}
.quote-quotation {
	width: 1rem;
	height: auto;
}

.expand {
	-webkit-appearance: none;
	        appearance: none;
	color: white;
	opacity: 0.5;
	cursor: pointer;
	margin: 0;
	font-size: 0.8em;
	width: -webkit-max-content;
	width: max-content;
	z-index: 1;
	font-family: "Poppins", sans-serif;
}
.expand::before {
	content: ". . .";
	font-weight: bold;
}
.expand:checked::before {
	content: "Collapse";
	font-weight: 400;
	font-family: "Poppins", sans-serif;
}
.expand:hover::before,
.expand:checked:hover::before {
	text-decoration: underline;
}

.unchecked::before {
	content: "";
	position: absolute;
	height: calc(1em * var(--line-height));
	width: 100%;
	left: 0;
	bottom: 0;
	pointer-events: none;
	background: linear-gradient(to bottom, transparent, var(--darkest-blue));
}
.checked {
	max-height: none;
}

/* Remove max-height on comment when checked */
.comment-container .comment-message.checked {
	max-height: none;
}

/* Don't show quote when unchecked */
.comment-message.unchecked .quote {
	display: none;
}
/* Show quote if it is the only message */
.comment-message.single .quote {
	display: initial !important;
}

/* Smaller font-size of quote message */
.quote .comment-message {
	font-size: 1rem;
}
.comment-message.unexpand ~ .expand {
	display: none !important;
}
.comment-message.unexpand {
	max-height: none;
}
.unchecked::before {
	content: "";
	position: absolute;
	height: calc(1em * var(--line-height));
	width: 100%;
	left: 0;
	bottom: 0;
	pointer-events: none;
	background: linear-gradient(to bottom, transparent, var(--darkest-blue));
}
.checked {
	max-height: none;
}
.unexpand.unchecked::before {
	display: none !important;
}
.unexpand.single.unchecked::before {
	display: none !important;
}
.hide {
	display: none;
}
.quote .comment-message.unexpand.unchecked::before {
	display: none;
}

.show {
	-webkit-animation: slide-left 0.25s ease-in forwards;
	animation: slide-left 0.25s ease-in forwards;
}

@-webkit-keyframes slide-left {
	0% {
		-webkit-transform: translateX(100%);
		transform: translateX(100%);
		opacity: 0;
		/* scale: 0.8; */
	}
	100% {
		-webkit-transform: translateX(0);
		transform: translateX(0);
		opacity: 1;
		/* scale: 1; */
	}
}
@keyframes slide-left {
	0% {
		-webkit-transform: translateX(100%);
		transform: translateX(100%);
		opacity: 0;
		/* scale: 0.8; */
	}
	100% {
		-webkit-transform: translateX(0);
		transform: translateX(0);
		opacity: 1;
		/* scale: 0.1; */
	}
}

.paginate-container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	margin-top: 3rem;
}
.paginate-double-arrow-container {
	cursor: pointer;
	width: 15px;
	color: white;
	margin: 0 1rem;
}
.paginate-current {
	color: var(--orange);
}
.paginate-container button {
	text-decoration: none;
	border: none;
	background-color: transparent;
	margin: 0 1rem;
	font-size: 1.25rem;
	font-family: "Poppins", sans-serif;
}
.paginate-more {
	color: white;
}
.paginate-btn {
	color: white;
	cursor: pointer;
}
.paginate-btn:hover {
	text-decoration: underline;
}

@media screen and (max-width: 500px) {
	.paginate-container {
		width: 90vw;
	}
	.paginate-double-arrow-container {
		width: 15px;
	}
	.paginate-container button {
		margin: 0;
		font-size: 4vw;
	}
}

.select-container {
  color: var(--darkest-blue);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 200px;
  border-radius: 5px;
  overflow: hidden;
}
.placeholder-option {
  display: flex;
  justify-content: space-between;
  background-color: white;
  padding: 0.5rem;
}
.select-arrow-icon {
  width: 1.5rem;
  height: 1.5rem;
  background-color: var(--light-blue);
  border-radius: 50%;
}
.select-check-icon {
  width: 1.5rem;
  color: white;
  background-color: var(--green);
  border-radius: 50%;
}
.select-options-container {
  background-color: var(--light-blue);
  width: 100%;
}
.select-option-wrapper {
  display: flex;
  align-items: center;
  margin: 0.5rem 0.5rem;
}
.select-option {
  width: 100%;
  cursor: pointer;
  list-style: none;
}

.filter-menu {
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  background-color: var(--blue);
  color: var(--grey);
}
.filter-icon {
  width: 1.75rem;
  color: white;
  cursor: pointer;
}
.filter-item {
  margin: 0.5rem 0.5rem;
}

@media screen and (max-width: 650px) {
  .filter-menu {
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
  }
  .filter-icon {
    width: 1.25rem;
  }
}

.posts-dashboard-container {
	padding-top: var(--header-height);
	height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	font-family: "Raleway", sans-serif;
}
.posts-dashboard-header {
	position: fixed;
	height: 150px;
	width: 100vw;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-around;
	background: rgb(31, 40, 45, 0.85);
	color: var(--grey);
	backdrop-filter: blur(10px);
	-webkit-backdrop-filter: blur(10px);
	z-index: 75;
}
.posts-dashboard-title {
	font-family: "Raleway", sans-serif;
}
.search-icon {
	cursor: pointer;
}
.posts-main-content {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	padding-bottom: 100px;
}
.posts-container {
	/* margin-top: 150px; */
	margin-top: calc(15vh + 2rem);
	display: flex;
	flex-direction: column;
	/* justify-content: space-evenly; */
	align-items: center;
	grid-row-gap: 2rem;
	row-gap: 2rem;
}
.posts-wave {
	position: fixed;
	bottom: 0;
	-webkit-backdrop-filter: blur(1px);
	        backdrop-filter: blur(1px);
	z-index: 100;
}

@media screen and (max-width: 650px) {
	.posts-dashboard-header {
		height: 125px;
	}
	.posts-dashboard-title {
		font-size: 5.5vw;
	}
	.posts-main-content {
		padding-bottom: 5vh;
	}
	.posts-container {
		/* make sure margin-top === posts-dashboard-header height */
		margin-top: 125px;
	}
}

@media screen and (max-width: 500px) {
	.posts-dashboard-header {
		height: 15vh;
	}
	.posts-dashboard-title {
		font-size: 6vw;
	}
	.posts-container {
		margin-top: 15vh;
	}
}

.image-carousel-container {
  width: 80%;
  height: 80%;
  background-color: white;
  position: fixed;
  top: 50%;
  left: 0;
  -webkit-transform: translate(12.5%, -50%);
          transform: translate(12.5%, -50%);
  z-index: 100;
}
.carousel-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.carousel-center {
  width: 85%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}
.active-image-container {
  position: relative;
  width: 100%;
  height: 90%;
  background-color: var(--light-grey);
  display: flex;
  justify-content: center;
  align-items: center;
}
.carousel-slide {
  width: 100%;
  height: 100%;
  object-fit: contain;
  position: absolute;
}
.full {
  transition: opacity 0.5s ease;
}
.image-count {
  bottom: 1%;
  font-size: 1.5rem;
}
.arrow-btn {
  height: 50px;
  width: 50px;
  top: 50%;
  border-radius: 100%;
  text-decoration: none;
  border: none;
  background-color: var(--lighter-blue);
  color: var(--darkest-blue);
  z-index: 10;
  cursor: pointer;
}
.arrow-btn:hover {
  background-color: var(--light-blue);
}
.close-btn {
  text-decoration: none;
  border: none;
  width: 40px;
  height: 40px;
  background-color: transparent;
  position: absolute;
  top: 1%;
  right: 0.5%;
  color: var(--dark-grey);
  background-color: var(--grey);
  border-radius: 100%;
  cursor: pointer;
}
.close-btn * {
  margin: 0.5rem;
}
.close-btn:hover {
  background-color: var(--red);
  color: white;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;
}

@media screen and (max-width: 800px) {
  .image-carousel-container {
    width: 100vw;
    height: calc(100vh - var(--header-height) - 5vh);
    margin-top: var(--header-height);
    top: 0;
    -webkit-transform: translatex(0%);
            transform: translatex(0%);
  }
  .active-image-container {
    width: 100vw;
    height: 85%;
  }
  .arrow-btn {
    width: 40px;
    height: 40px;
    display: inline-block;
    position: absolute;
    border-radius: 100%;
  }
  .arrow-btn:nth-child(1) {
    left: 0;
  }
  .arrow-btn:nth-child(3) {
    right: 0;
  }
}

.faq-container {
  padding-top: var(--header-height);
  height: 100vh;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  overflow-x: hidden;
}
.faq-title {
  padding: 1rem;
  margin-top: 1rem;
}
.faq-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  height: 40vh;
}
.faq-illustration {
  height: 25vh;
}
.faq-question-container {
  margin-bottom: 1rem;
  margin-right: 1rem;
  width: 425px;
  white-space: pre-wrap;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--darkest-blue);
  background-color: var(--lighter-blue);
  padding: 1rem;
}
.faq-question-container:hover {
  cursor: pointer;
}
.faq-question {
  text-align: left;
}
.arrow {
  width: 1.5rem;
  border-radius: 50%;
  background-color: white;
}
.faq-answer-container {
  background-color: var(--light-blue);
  width: 425px;
  white-space: pre-wrap;
}
.faq-answer {
  color: var(--darkest-blue);
  text-align: left;
  padding: 1rem;
}
::-webkit-scrollbar {
  width: 5px;
  background: transparent;
}
::-webkit-scrollbar-thumb {
  background: var(--grey);
}
::-webkit-scrollbar-corner {
  display: none;
}
@media screen and (max-width: 530px) {
  .faq-title {
    font-size: 1.75rem;
  }
  .faq-content {
    height: 35vh;
  }
  .faq-question-container {
    width: 85vw;
    padding: 0.75rem;
  }
  .faq-illustration {
    height: 20vh;
  }
  .faq-question {
    font-size: 0.95rem;
  }
  .faq-answer-container {
    width: 85vw;
  }
  .faq-answer {
    font-size: 0.95rem;
  }
}

.pnf-container {
  height: 100vh;
  padding-top: var(--header-height);
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  font-family: "Raleway", sans-serif;
}
.pnf-header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
}
.pnf-header * {
  padding: 1rem;
}
.pnf-message {
  font-size: 2.75rem;
}
.pnf-description {
  color: var(--dark-grey);
  font-size: 1.25rem;
  line-height: 1.25;
  width: 65%;
}
.error-illustration {
  width: 500px;
}

@media screen and (max-width: 670px) {
  .pnf-description {
    width: 90%;
  }
}
@media screen and (max-width: 520px) {
  .pnf-message {
    font-size: 2.15rem;
  }
  .error-illustration {
    width: 75vw;
  }
}

* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}
:root {
	--darkest-blue: #1f282d;
	--dark-blue: #263137;
	--blue: #293e49;
	--light-blue: #a6b9c2;
	--lighter-blue: #bbc9d0;
	--orange: #ffc58f;
	--dark-grey: #9b9b9b;
	--grey: #cccaca;
	--light-grey: #e3e3e3;
	--green: #a2d6a0;
	--red: #e46f6f;
	--header-height: 60px;
}
body {
	background-color: #1f282d;
	background-color: var(--darkest-blue);
	font-family: "Poppins", sans-serif;
	font-weight: 400;
}

