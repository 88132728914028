.comments-container {
	width: auto;
	overflow: hidden;
	background-color: var(--darkest-blue);
	/* background-color: #21272d; */

	display: flex;
	flex-direction: column;
	--comment-padding: 5px;
	gap: calc(var(--comment-padding) * 1.5);
	padding: var(--comment-padding);
	font-family: "Poppins", sans-serif;
	font-weight: 200;
	/* max-height: 500px; */
	max-height: calc((150px * 3) + (calc(var(--comment-padding) * 1.5) * 2));
	overflow-y: auto;
}
.scrolltop {
	display: block;
	position: sticky;
	/* top: calc(var(--header-height) + 150px + 16px); */
	/* top: 50%; */
	top: 0;
	align-self: left;
	width: 20px;
	aspect-ratio: 1;
	border-radius: 25px;
	border: none;
	background-color: white;
	z-index: 10;
}
.progressbar {
	width: 100%;
	position: relative;
	overflow: hidden;
	display: block;
	height: 1px;
	z-index: 0;
	background-color: var(--dark-blue);
	border-radius: 16px;
}
.pill {
	position: absolute;
	left: 0;
	bottom: 0;
	top: 0;
	border-radius: 16px;
	transition: transform 0.2s linear;
	transform-origin: left;
	background-color: var(--orange);
	width: auto;
}
.pill:first-child {
	animation: animation-test 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395)
		infinite;
}
@keyframes animation-test {
	0% {
		left: -35%;
		right: 100%;
	}
	60% {
		left: 100%;
		right: -90%;
	}
	100% {
		left: 100%;
		right: -90%;
	}
}
.pill:last-child {
	animation: animation-test1 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) 1.15s
		infinite;
}
@keyframes animation-test1 {
	0% {
		left: -200%;
		right: 100%;
	}
	60% {
		left: 107%;
		right: -8%;
	}
	100% {
		left: 107%;
		right: -8%;
	}
}
.comments-container h2 {
	font-size: 115%;
	color: white;
}
.comment-container {
	background-color: rgba(255, 255, 255, 0.03);
	border-radius: 5px;
	/* padding: 10px; */
	display: flex;
	flex-direction: column;
	gap: 0.5rem;

	font-size: 100%;

	/* --max-lines: 3;
	--line-height: 1.5; */

	/* overflow: hidden; */
	position: relative;
	border-bottom: 1px solid rgba(255, 255, 255, 0.03);
	/* padding-bottom: 10px; */
	padding-bottom: calc(1em * 0.5);
}
.comment-container > * {
	padding: 0 0.5rem;
}
.comment-container img[class="smiley"] {
	height: 1.5em;
	width: max-content;
	/* overflow-clip-margin: content-box;
	overflow: clip;
	vertical-align: bottom; */
}
.comment-info {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 5px;
	background-color: rgba(255, 255, 255, 0.015);
	gap: 10px;
}
.comment-reply {
	display: flex;
	align-items: center;
	gap: 5px;
	text-decoration: none;
	flex-shrink: 0;
}
.comment-date {
	font-size: 0.75em;
	color: rgba(255, 255, 255, 0.25) !important;
	flex-shrink: 0;
}
.comment-commenter {
	flex-shrink: 1;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.starter {
	color: var(--orange) !important;
}
[data-tooltip] {
	position: relative;
	cursor: pointer;
}
.comment-info *:first-child,
.comment-info *:last-child {
	color: rgba(255, 255, 255, 0.5);
	font-size: 0.9em;
}
.comment-icon {
	width: 1rem;
	height: auto;
	/* height: 1rem; */
	/* width: auto; */
	opacity: 0.25;
}
.comment-container * {
	max-width: 100% !important;
	color: white;
}
.comment-container a {
	word-break: break-all;
}

.comment-message {
	max-height: calc(var(--max-lines) * 1em * var(--line-height));
	--max-height: calc(var(--max-lines) * 1em * var(--line-height));
	line-height: var(--line-height);
	overflow: hidden;
	display: flex;
	flex-direction: column;

	/* max-height: none; */
}

.quote {
	background-color: rgba(255, 255, 255, 0.03);
	/* border-left: 2px solid var(--orange); */
	border-left: 2px solid white;
	padding: 10px;
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
	position: relative;
	margin-bottom: 0.25rem;
}
.quote-top {
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-size: 0.8em;
}
.quote-from {
	display: flex;
	gap: 0.25rem;
}
.quote-quotation {
	width: 1rem;
	height: auto;
}

.expand {
	appearance: none;
	color: white;
	opacity: 0.5;
	cursor: pointer;
	margin: 0;
	font-size: 0.8em;
	width: max-content;
	z-index: 1;
	font-family: "Poppins", sans-serif;
}
.expand::before {
	content: ". . .";
	font-weight: bold;
}
.expand:checked::before {
	content: "Collapse";
	font-weight: 400;
	font-family: "Poppins", sans-serif;
}
.expand:hover::before,
.expand:checked:hover::before {
	text-decoration: underline;
}

.unchecked::before {
	content: "";
	position: absolute;
	height: calc(1em * var(--line-height));
	width: 100%;
	left: 0;
	bottom: 0;
	pointer-events: none;
	background: linear-gradient(to bottom, transparent, var(--darkest-blue));
}
.checked {
	max-height: none;
}

/* Remove max-height on comment when checked */
.comment-container .comment-message.checked {
	max-height: none;
}

/* Don't show quote when unchecked */
.comment-message.unchecked .quote {
	display: none;
}
/* Show quote if it is the only message */
.comment-message.single .quote {
	display: initial !important;
}

/* Smaller font-size of quote message */
.quote .comment-message {
	font-size: 1rem;
}
.comment-message.unexpand ~ .expand {
	display: none !important;
}
.comment-message.unexpand {
	max-height: none;
}
.unchecked::before {
	content: "";
	position: absolute;
	height: calc(1em * var(--line-height));
	width: 100%;
	left: 0;
	bottom: 0;
	pointer-events: none;
	background: linear-gradient(to bottom, transparent, var(--darkest-blue));
}
.checked {
	max-height: none;
}
.unexpand.unchecked::before {
	display: none !important;
}
.unexpand.single.unchecked::before {
	display: none !important;
}
.hide {
	display: none;
}
.quote .comment-message.unexpand.unchecked::before {
	display: none;
}

.show {
	-webkit-animation: slide-left 0.25s ease-in forwards;
	animation: slide-left 0.25s ease-in forwards;
}

@-webkit-keyframes slide-left {
	0% {
		-webkit-transform: translateX(100%);
		transform: translateX(100%);
		opacity: 0;
		/* scale: 0.8; */
	}
	100% {
		-webkit-transform: translateX(0);
		transform: translateX(0);
		opacity: 1;
		/* scale: 1; */
	}
}
@keyframes slide-left {
	0% {
		-webkit-transform: translateX(100%);
		transform: translateX(100%);
		opacity: 0;
		/* scale: 0.8; */
	}
	100% {
		-webkit-transform: translateX(0);
		transform: translateX(0);
		opacity: 1;
		/* scale: 0.1; */
	}
}
