.filter-menu {
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  background-color: var(--blue);
  color: var(--grey);
}
.filter-icon {
  width: 1.75rem;
  color: white;
  cursor: pointer;
}
.filter-item {
  margin: 0.5rem 0.5rem;
}

@media screen and (max-width: 650px) {
  .filter-menu {
    left: 50%;
    transform: translateX(-50%);
  }
  .filter-icon {
    width: 1.25rem;
  }
}
