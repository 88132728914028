.header-nav {
  display: flex;
  align-items: center;
  height: var(--header-height);
  padding: 0px 1rem;
  position: fixed;
  width: 100vw;
  background-color: var(--dark-blue);
  filter: blur(0);
  z-index: 100;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 10px;
  font-family: "Raleway", sans-serif;
}
.burger-nav {
  display: none;
  z-index: 1;
}
.burger-nav div {
  width: 25px;
  height: 3px;
  margin: 5px;
  background-color: white;
}
.logo-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
}
.logo-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  color: white;
}
.logo {
  width: 30px;
}
.logo-name {
  font-size: 1.5rem;
}
.main-nav {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex: 1;
}
.main-nav * {
  list-style: none;
  color: white;
  text-decoration: none;
}
.main-nav *:hover {
  color: lightgrey;
}
.blur {
  filter: blur(3px);
}
.no-scroll {
  overflow: hidden;
}

@media screen and (max-width: 800px) {
  .header-nav {
    justify-content: center;
  }
  .logo-container {
    justify-content: center;
  }
  .logo-name {
    font-size: 1.5rem;
  }
  .main-nav {
    position: absolute;
    left: -2rem;
    height: 100vh;
    width: 250px;
    top: 0;
    background-color: #263137;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    text-align: center;
    transform: translateX(-100%);
    transition: transform 0.25s ease-in;
    box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.25);
  }
  .burger-nav {
    display: inline;
    position: absolute;
    left: 5%;
  }
  .nav-active {
    transform: translateX(0%);
    transition: transform 0.25s ease-in;
  }
  .toggle:nth-child(1) {
    transform: rotate(-45deg) translate(-5px, 6px);
    transition: transform 0.25s;
  }
  .toggle:nth-child(2) {
    opacity: 0;
  }
  .toggle:nth-child(3) {
    transform: rotate(45deg) translate(-5px, -6px);
    transition: transform 0.25s;
  }
}
