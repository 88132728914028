.posts-dashboard-container {
	padding-top: var(--header-height);
	height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	font-family: "Raleway", sans-serif;
}
.posts-dashboard-header {
	position: fixed;
	height: 150px;
	width: 100vw;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-around;
	background: rgb(31, 40, 45, 0.85);
	color: var(--grey);
	backdrop-filter: blur(10px);
	-webkit-backdrop-filter: blur(10px);
	z-index: 75;
}
.posts-dashboard-title {
	font-family: "Raleway", sans-serif;
}
.search-icon {
	cursor: pointer;
}
.posts-main-content {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	padding-bottom: 100px;
}
.posts-container {
	/* margin-top: 150px; */
	margin-top: calc(15vh + 2rem);
	display: flex;
	flex-direction: column;
	/* justify-content: space-evenly; */
	align-items: center;
	row-gap: 2rem;
}
.posts-wave {
	position: fixed;
	bottom: 0;
	backdrop-filter: blur(1px);
	z-index: 100;
}

@media screen and (max-width: 650px) {
	.posts-dashboard-header {
		height: 125px;
	}
	.posts-dashboard-title {
		font-size: 5.5vw;
	}
	.posts-main-content {
		padding-bottom: 5vh;
	}
	.posts-container {
		/* make sure margin-top === posts-dashboard-header height */
		margin-top: 125px;
	}
}

@media screen and (max-width: 500px) {
	.posts-dashboard-header {
		height: 15vh;
	}
	.posts-dashboard-title {
		font-size: 6vw;
	}
	.posts-container {
		margin-top: 15vh;
	}
}
