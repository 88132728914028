@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway&display=swap");
* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}
:root {
	--darkest-blue: #1f282d;
	--dark-blue: #263137;
	--blue: #293e49;
	--light-blue: #a6b9c2;
	--lighter-blue: #bbc9d0;
	--orange: #ffc58f;
	--dark-grey: #9b9b9b;
	--grey: #cccaca;
	--light-grey: #e3e3e3;
	--green: #a2d6a0;
	--red: #e46f6f;
	--header-height: 60px;
}
body {
	background-color: var(--darkest-blue);
	font-family: "Poppins", sans-serif;
	font-weight: 400;
}
