.image-carousel-container {
  width: 80%;
  height: 80%;
  background-color: white;
  position: fixed;
  top: 50%;
  left: 0;
  transform: translate(12.5%, -50%);
  z-index: 100;
}
.carousel-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.carousel-center {
  width: 85%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}
.active-image-container {
  position: relative;
  width: 100%;
  height: 90%;
  background-color: var(--light-grey);
  display: flex;
  justify-content: center;
  align-items: center;
}
.carousel-slide {
  width: 100%;
  height: 100%;
  object-fit: contain;
  position: absolute;
}
.full {
  transition: opacity 0.5s ease;
}
.image-count {
  bottom: 1%;
  font-size: 1.5rem;
}
.arrow-btn {
  height: 50px;
  width: 50px;
  top: 50%;
  border-radius: 100%;
  text-decoration: none;
  border: none;
  background-color: var(--lighter-blue);
  color: var(--darkest-blue);
  z-index: 10;
  cursor: pointer;
}
.arrow-btn:hover {
  background-color: var(--light-blue);
}
.close-btn {
  text-decoration: none;
  border: none;
  width: 40px;
  height: 40px;
  background-color: transparent;
  position: absolute;
  top: 1%;
  right: 0.5%;
  color: var(--dark-grey);
  background-color: var(--grey);
  border-radius: 100%;
  cursor: pointer;
}
.close-btn * {
  margin: 0.5rem;
}
.close-btn:hover {
  background-color: var(--red);
  color: white;
  transform: rotate(90deg);
  transition: transform 0.5s;
}

@media screen and (max-width: 800px) {
  .image-carousel-container {
    width: 100vw;
    height: calc(100vh - var(--header-height) - 5vh);
    margin-top: var(--header-height);
    top: 0;
    transform: translatex(0%);
  }
  .active-image-container {
    width: 100vw;
    height: 85%;
  }
  .arrow-btn {
    width: 40px;
    height: 40px;
    display: inline-block;
    position: absolute;
    border-radius: 100%;
  }
  .arrow-btn:nth-child(1) {
    left: 0;
  }
  .arrow-btn:nth-child(3) {
    right: 0;
  }
}
