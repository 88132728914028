.loading-animation {
  position: relative;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loading-animation span {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 2px solid;
  pointer-events: none;
  animation: load 3s linear infinite;
}
.loading-animation span:nth-child(1) {
  border-radius: 57% 43% 56% 44% / 48% 62% 38% 52%;
  border-color: white;
}
.loading-animation span:nth-child(2) {
  animation-direction: reverse;
  border-radius: 74% 26% 69% 31% / 38% 75% 25% 62%;
  border-color: var(--blue);
}
.loading-animation span:nth-child(3) {
  animation-duration: 3s;
  border-radius: 35% 65% 69% 31% / 38% 50% 50% 62%;
  border-color: var(--darker-blue);
}
@keyframes load {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
